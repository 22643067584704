import React, { Component } from 'react';
import styled from 'styled-components';
import { compose, withApollo } from 'react-apollo';

const StyledLinkHeading = styled.div`
  position: relative;
  width: 100%;
  padding: 20px;
  background: white;
  user-select: none;
  border-bottom: solid 2px rgb(230, 230, 230);
  text-align: center;
  float: left;

  &:hover {
    cursor: pointer;
  }

  &.bid {
    background: #333;
    color: white;
  }
`;

const StyledLinkArrow = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
`;

class LinkMobileitem extends Component {
  _handleOpenModal(type) {
    const { client } = this.props;
    client.writeData({
      data: {
        objectPage: {
          __typename: 'ObjectPage',
          modal: {
            __typename: 'Modal',
            show: true,
            type: type,
            scroll: window.pageYOffset
          }
        }
      }
    });
    if (typeof document !== 'undefined') {
      document.body.classList.add('no-scroll');
    }
  }

  render() {
    const { onClick, openModal, type, estateId, className, label, url } = this.props;
    return (
      <StyledLinkHeading
        className={className}
        onClick={
          openModal
            ? () => {
                if (type === 'valuation') {
                  window.open(
                    url && url.length ? url : 'https://selge.garanti.no',
                    '_blank'
                  );
                } else if (type === 'showing') {
                  if (typeof window !== 'undefined' && estateId) {
                    window.open(
                      `https://meglervisning.no/registrer/msgar/${estateId}`,
                      '_blank'
                    );
                  }
                } else {
                  this._handleOpenModal(type);
                }
              }
            : onClick
        }
      >
        {label ? label : null}
        <StyledLinkArrow className={className} />
      </StyledLinkHeading>
    );
  }
}

export default compose(withApollo)(LinkMobileitem);
