import styled from 'styled-components';

export const Sold = styled.div`
  width: 70px;
  padding: 10px;
  font-size: 20px;
  color: white;
  background: black;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  ${(props) => (props.mobile ? 'margin-top: 20px;' : '')}
`;
