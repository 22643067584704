import styled from 'styled-components';

export const Button = styled.button`
  padding: 15px;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  appearance: none;
  border: none;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
