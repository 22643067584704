import React, { Component } from 'react';
import { Query, withApollo, compose } from 'react-apollo';
import { GET_REDIRECT_INFO } from '../../../client/__graphql__/queries';

class RedirectCheck extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { sguid, path, children } = this.props;
    if (typeof sguid !== 'string') return null;
    if (!children) return null;
    return (
      <Query
        query={GET_REDIRECT_INFO}
        ssr={false}
        fetchPolicy="network-only"
        variables={{
          input: {
            sguid: sguid,
            path: path ? path : undefined,
            host:
              typeof window !== 'undefined' && window.location
                ? window.location.hostname
                : undefined
          }
        }}
      >
        {({ data, loading, error }) => {
          if (loading) return null;

          if (
            typeof window !== 'undefined' &&
            window.location &&
            data.getRedirectInfo.redirect &&
            data.getRedirectInfo.url
          ) {
            window.location.href = data.getRedirectInfo.url;
            return null;
          }

          return children;
        }}
      </Query>
    );
  }
}

export default compose(withApollo)(RedirectCheck);
