import gql from 'graphql-tag';

export const TOGGLE_GALLERY = gql`
  mutation ToggleGallery {
    toggleGallery @client
  }
`;

export const TOGGLE_GALLERY_PREVIEW = gql`
  mutation ToggleGalleryPreview {
    toggleGalleryPreview @client
  }
`;

export const TOGGLE_MENU = gql`
  mutation ToggleMenu($toggle: Boolean) {
    toggleMenu(toggle: $toggle) @client
  }
`;

export const TOGGLE_SEARCH = gql`
  mutation ToggleSearch($toggle: Boolean) {
    toggleSearch(toggle: $toggle) @client
  }
`;

export const POST_INTERESTED_MUTATION = gql`
  mutation RegistrerInteressent($input: RegistrerInteressentInput!) {
    registrerInteressent(input: $input) {
      success
    }
  }
`;
