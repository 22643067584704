export const getSizeData = (data) => {
  if (!data) return null;
  const { area = null, size = null } = data;
  if (size) {
    const { usableInternalArea, usableInternalAreaTo, primaryArea, primaryAreaTo } = size;
    if (usableInternalArea)
      return {
        type: 'usableInternal',
        from: usableInternalArea,
        to: usableInternalAreaTo ? usableInternalAreaTo : null
      };

    if (primaryArea) {
      return {
        type: 'primaryRoom',
        from: primaryArea,
        to: primaryAreaTo ? primaryAreaTo : null
      };
    }
  }

  if (!area) return null;
  const { value, from, to } = area;
  if (from) {
    return {
      type: 'primaryRoom',
      from: from,
      to: to ? to : null
    };
  }
  if (!value) return null;
  return {
    type: 'primaryRoom',
    from: value,
    to: null
  };
};
