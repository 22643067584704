export const defaults = {
  data: {
    view: {
      __typename: 'View',
      size: {
        __typename: 'Size',
        width: 0,
        height: 0,
        scrollTop: 0,
        currentScroll: 0
      }
    },
    common: {
      __typename: 'Common',
      menu: {
        __typename: 'Menu',
        show: false,
        showSearch: false
      }
    },
    objectPage: {
      __typename: 'ObjectPage',
      externalLink: false,
      infotab: {
        __typename: 'InfoTab',
        show: false,
        sectionOpen: false,
        currentSection: ''
      },
      gallery: {
        __typename: 'Gallery',
        show: false,
        galleryOpen: false,
        showVideo: false,
        loading: true
      },
      modal: {
        __typename: 'Modal',
        show: false,
        type: '',
        scroll: 0
      }
    }
  }
};
