import React from 'react';
import styled from 'styled-components';

export const Input = ({ placeholder, error, value, onChange }) => {
  return (
    <StyledInputWrapper>
      <StyledInput
        className={error ? 'error' : ''}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </StyledInputWrapper>
  );
};

const StyledInputWrapper = styled.div``;

const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  font-size: 16px;
  color: rgb(150, 150, 150);
  border-radius: 0;
  border: solid 2px transparent;
  appearance: none;
  outline: none;

  &.error {
    border: solid 2px rgb(230, 30, 0);
    color: rgb(230, 30, 0);

    &::placeholder {
      color: rgb(230, 30, 0);
    }
  }

  @media all and (max-width: 960px) {
    background: rgb(245, 245, 245);
  }
`;
