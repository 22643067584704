import React, { Component } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query, compose, withApollo, graphql } from 'react-apollo';
import { CACHE } from '../../../client/__graphql__/queries';
import { TOGGLE_MENU } from '../../../client/__graphql__/mutations';
import { Spring, Trail, animated } from 'react-spring/renderprops.cjs';

import { Logo } from '../ui/Logo';
import { MenuIcon } from '../ui/MenuIcon';

const StyledHeader = styled.div`
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  width: 100%;
  height: ${(props) => (props.changeMenu ? '60px' : '100px')};
  padding: 0 40px;
  z-index: 9999;
  ${(props) => (props.changeMenu ? 'background: rgb(230, 230, 230);' : '')}

  @media screen and (max-width: 960px) {
    position: fixed;
  }
`;

const StyledInsideHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-bottom: ${(props) =>
    props.show && !props.infoTabOpen ? `solid 2px white` : `solid 2px transparent`};

  @media screen and (max-width: 960px) {
    border-bottom: none;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    transition: none;
  }
`;

const StyledCloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledCloseLabel = styled.div`
  margin-right: 10px;
  font-weight: bold;
`;

const StyledCloseIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url('https://prod-objektside-garanti.reeltime.no/objektside/images/close_icon.svg')
    no-repeat;
  background-size: contain;
  background-position: center;
  font-weight: bold;
`;

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
`;

const Menu = styled.ul`
  display: flex;
  flex-flow: row-reverse;
  transition: opacity 0.2s ease-in-out;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 20px;
  opacity: ${(props) => (props.show ? 1 : 0)};

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const MenuLink = styled(animated.li)`
  margin-right: 40px;
  font-weight: 600;
  color: white;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    margin-right: 40px;
  }
`;

const StyledGalleryButton = styled.div`
  transition: background .2s ease-in-out;
  position: absolute;
  right: -5px;
  bottom: 0px;
  width: 30px;
  height: 20px;
  background: ${(props) =>
    props.open
      ? 'url(https://prod-objektside-garanti.reeltime.no/objektside/images/gallery_icon_open.svg)'
      : 'url(https://prod-objektside-garanti.reeltime.no/objektside/images/gallery_icon.svg)'}
    no-repeat;
  background-size: 30px;
  background-position: center;
  z-index: 101;

  @media not all and (min-resolution:.001dpcm) { 
    transition: none;
  }
}

  &:hover {
    cursor: pointer;
  }
`;

class CloseIcon extends Component {
  render() {
    const { label, style, onClick } = this.props;
    return (
      <StyledCloseIconWrapper style={style} onClick={onClick}>
        <StyledCloseLabel>{label}</StyledCloseLabel>
        <StyledCloseIcon />
      </StyledCloseIconWrapper>
    );
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      menuItems: [
        {
          key: 1,
          text: 'Våre kontorer',
          href: 'https://garanti.no/kontorer'
        },
        { key: 2, text: 'Om oss', href: 'https://garanti.no/omoss' },
        { key: 3, text: 'Budgiving', href: 'https://garanti.no/budgivning' },
        { key: 4, text: 'Kjøpe', href: 'https://garanti.no/kjope' },
        { key: 5, text: 'Selge', href: 'https://garanti.no/selge' }
      ],
      scrollPos: 0
    };
  }

  _closeGallery() {
    let { client } = this.props;
    client.writeData({
      data: {
        objectPage: {
          __typename: 'ObjectPage',
          gallery: {
            __typename: 'Gallery',
            show: false,
            galleryOpen: false,
            showVideo: false
          }
        }
      }
    });
    document.body.classList.remove('no-scroll');
  }

  _toggleGallery() {
    const { client } = this.props;
    const { objectPage } = client.readQuery({
      query: gql`
        query {
          objectPage {
            gallery {
              galleryOpen
            }
          }
        }
      `
    });

    if (objectPage.gallery.galleryOpen) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }

    client.writeData({
      data: {
        objectPage: {
          __typename: 'ObjectPage',
          gallery: {
            __typename: 'Gallery',
            galleryOpen: !objectPage.gallery.galleryOpen
          }
        }
      }
    });
  }

  render() {
    const { data } = this.props;
    return (
      <Query query={CACHE} fetchPolicy="cache-only">
        {({ data: { view, objectPage, common } }) => {
          let galleryShow =
            objectPage && objectPage.gallery ? objectPage.gallery.show : false;
          let menuOpen = common && common.menu && common.menu.show ? true : false;
          let infoTabOpen =
            objectPage.infotab && objectPage.infotab && objectPage.infotab.show;
          let changeMenu =
            view &&
            view.size &&
            view.size.scrollTop > 100 &&
            view.size.width < 960 &&
            !galleryShow;
          return (
            <Spring from={{ x: 1 }} to={{ x: !galleryShow ? 1 : 0 }}>
              {({ x }) => {
                return (
                  <StyledHeader changeMenu={changeMenu}>
                    <StyledInsideHeader
                      show={!galleryShow}
                      kbbl={data && data.isKbbl}
                      infoTabOpen={infoTabOpen}
                    >
                      {data ? (
                        <Logo
                          changeMenu={changeMenu}
                          show={!galleryShow}
                          kbbl={data && data.isKbbl}
                          abbl={data && data.isAbbl}
                          nbbo={data && data.isNbbo}
                          storheia={data && data.isStorheia}
                          kobbl={data && data.isKobbl}
                          proffmegleren={data && data.isProffmegleren}
                          isWhitelabel={data && data.isWhitelabel}
                          whitelabelOfficeUrl={data && data.whitelabelOfficeUrl}
                        />
                      ) : null}
                      <MenuWrapper>
                        {!galleryShow && data && data.isWhitelabel === false ? (
                          <Menu show={true}>
                            <Trail
                              items={this.state.menuItems}
                              keys={(item) => item.key}
                              from={{
                                opacity: 0,
                                transform: 'translate3d(10px,0,0)'
                              }}
                              to={{
                                opacity: menuOpen ? 1 : 0,
                                transform: menuOpen
                                  ? 'translate3d(0,0,0)'
                                  : 'translate3d(10px,0,0)'
                              }}
                            >
                              {(item) => (props) => {
                                return (
                                  <MenuLink
                                    onClick={() => {
                                      if (typeof window !== 'undefined') {
                                        window.location.href = item.href;
                                      }
                                    }}
                                    style={props}
                                  >
                                    {item.text}
                                  </MenuLink>
                                );
                              }}
                            </Trail>
                          </Menu>
                        ) : null}

                        {!galleryShow &&
                        data &&
                        data.isKbbl === false &&
                        data.isAbbl === false &&
                        data.isNbbo === false &&
                        data.isWhitelabel === false ? (
                          <MenuIcon
                            scrolled={changeMenu}
                            active={common && common.menu && common.menu.show}
                            onClick={() => {
                              this.props.toggleMenu();
                            }}
                          />
                        ) : null}
                        {galleryShow && !objectPage.gallery.showVideo ? (
                          <StyledGalleryButton
                            open={objectPage.gallery.galleryOpen}
                            onClick={() => this._toggleGallery()}
                          />
                        ) : null}
                        {galleryShow ? (
                          <CloseIcon label="" onClick={() => this._closeGallery()} />
                        ) : null}
                      </MenuWrapper>
                    </StyledInsideHeader>
                  </StyledHeader>
                );
              }}
            </Spring>
          );
        }}
      </Query>
    );
  }
}

export default compose(withApollo, graphql(TOGGLE_MENU, { name: 'toggleMenu' }))(Header);
