import React, { Component } from 'react';
import styled from 'styled-components';

const MenuIconWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 30px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const MenuIconLine = styled.span`
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out,
    color 0.2s ease-in-out;
  position: absolute;
  width: 30px;
  height: 4px;
  background: ${props =>
    props.active && props.currentTheme === 'black'
      ? 'black'
      : props.scrolled
      ? 'black'
      : 'white'};

  &.top {
    top: 0;
    bottom: ${props => (props.active ? 0 : 'auto')};
    transform: ${props => (props.active ? 'rotate(-45deg)' : 'rotate(0deg)')};
    margin: auto;
  }

  &.middle {
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: ${props => (props.active ? 0 : 1)};
  }

  &.bottom {
    top: ${props => (props.active ? 0 : 'auto')};
    bottom: 0;
    transform: ${props => (props.active ? 'rotate(45deg)' : 'rotate(0deg)')};
    margin: auto;
  }
`;

export class MenuIcon extends Component {
  render() {
    let { style, onClick, active, scrolled, currentTheme } = this.props;
    return (
      <MenuIconWrapper style={style} onClick={onClick}>
        <MenuIconLine
          className="top"
          currentTheme={currentTheme}
          scrolled={scrolled}
          active={active}
        />
        <MenuIconLine
          className="middle"
          currentTheme={currentTheme}
          scrolled={scrolled}
          active={active}
        />
        <MenuIconLine
          className="bottom"
          currentTheme={currentTheme}
          scrolled={scrolled}
          active={active}
        />
      </MenuIconWrapper>
    );
  }
}
