import '@babel/polyfill';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { defaults } from './client/__graphql__/defaults';
import { resolvers } from './client/__graphql__/resolvers';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);
cache.writeData(defaults);

const httpLink = createHttpLink({
  uri: `${window.env.api}/api/graphql`,
  credentials: 'same-origin',
});

const link = ApolloLink.from([errorLink, httpLink]);
const client = new ApolloClient({
  ssrForceFetchDelay: 100,
  cache: cache,
  link: link,
  resolvers,
});

render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
