import React, { Component } from 'react';
import styled, { injectGlobal } from 'styled-components';
import { Meta } from '../utils/Meta';
import Header from '../layout/Header';
import { Footer } from '../layout/Footer';

const Page = styled.div``;

const Heading = styled.h1`
  font-weight: 600;
  color: #000;
  text-align: center;
  max-width: 450px;
  margin-bottom: 0;
`;

const Text = styled.p`
  text-align: center;
`;

const Anchor = styled.a`
  font-size: 20px;
  font-weight: 600;
  color: #000;

  &:hover {
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding: 200px 40px;
  float: left;
`;

class NoMatch extends Component {
  render() {
    const { history } = this.props;
    return (
      <Page>
        <Meta title="Finner ikke side" />
        <Header currentTheme="black" {...this.props} />
        <ContentWrapper>
          <Heading>Finner ikke det du leter etter..</Heading>
          <Text>
            Siden eksisterer ikke. Dette kan være fordi den har blitt slettet,
            eller annonsen har gått ut på dato.
          </Text>
          <Anchor
            onClick={e => {
              e.preventDefault();
              history.push('/');
            }}
          >
            Gå til forsiden
          </Anchor>
        </ContentWrapper>
        <Footer />
      </Page>
    );
  }
}

export default NoMatch;
