import React from 'react';
import styled from 'styled-components';

const StyledInfoItem = styled.div`
  text-align: left;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

const StyledInfoLabel = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
`;

const StyledInfoValue = styled.div`
  display: block;
  font-size: 18px;
  font-weight: 300;

  div {
    margin-bottom: 5px;
  }

  sup {
    font-size: 10px;
  }
`;

export const InfoItem = ({ label, value, children }) => (
  <StyledInfoItem>
    <StyledInfoLabel>{label}</StyledInfoLabel>
    <StyledInfoValue>{children ? children : value}</StyledInfoValue>
  </StyledInfoItem>
);
