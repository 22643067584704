import React from 'react';
import styled from 'styled-components';

import { POST_INTERESTED_MUTATION } from '../../../client/__graphql__/mutations';
import { Form, FormRow, FormColumn } from '../ui/Form';
import { Mutation } from 'react-apollo';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { Checkbox } from '../ui/Checkbox';
import { emailValidation } from '../../utils/validateEmail';

export const BestillSalgsoppgave = ({ sguid, sold }) => {
  const [firstName, setFirstName] = React.useState('');
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [lastNameError, setLastNameError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);

  const [generalConsent, setGeneralConsent] = React.useState(false);
  const [keepMeInformedOnBids, setKeepMeInformedOnBids] = React.useState(false);
  const [keepMeInformed, setKeepMeInformed] = React.useState(false);
  const [wantFinance, setWantFinance] = React.useState(false);
  const [wantValuation, setWantValuation] = React.useState(false);

  const [success, setSuccess] = React.useState(false);

  const reset = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);
  };

  const afterSubmitted = () => {
    reset();
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setGeneralConsent(false);
    setKeepMeInformedOnBids(false);
    setKeepMeInformed(false);
    setWantFinance(false);
    setWantValuation(false);
    setSuccess(true);
  };

  return (
    <Scrollable>
      {!sold ? (
        <Mutation
          mutation={POST_INTERESTED_MUTATION}
          onCompleted={({ registrerInteressent }) => {
            if (registrerInteressent && registrerInteressent.success) {
              afterSubmitted();
            }
          }}
        >
          {(post, { loading, error }) => {
            if (success) {
              return (
                <Thanks>
                  <h1>Takk for din bestilling</h1>
                  <p>Du vil motta salgsoppgave på e-post.</p>
                </Thanks>
              );
            } else {
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    reset();
                    let errors = false;
                    if (!sguid) {
                      console.error('Mangler oppdragsnummer');
                      errors = true;
                    }
                    if (!firstName) {
                      setFirstNameError(true);
                      errors = true;
                    }
                    if (!lastName) {
                      setLastNameError(true);
                      errors = true;
                    }
                    if (!email || (email && !emailValidation(email))) {
                      setEmailError(true);
                      errors = true;
                    }
                    if (!phone || (phone && phone.length < 8)) {
                      setPhoneError(true);
                      errors = true;
                    }
                    if (errors === true) {
                      return;
                    }

                    post({
                      variables: {
                        input: {
                          sguid: String(sguid),
                          firstName,
                          lastName,
                          email,
                          mobilePhone: phone,
                          consent: {
                            keepMeInformedOnBids,
                            keepMeInformed,
                            wantFinance,
                            wantValuation,
                          },
                        },
                      },
                    });
                  }}
                >
                  <h1 style={{ fontWeight: '400' }}>Bestill salgsoppgave</h1>
                  {error &&
                    error.graphQLErrors &&
                    error.graphQLErrors.map((item, index) => {
                      return (
                        <FormNotification key={index} className="error">
                          {item.message}
                        </FormNotification>
                      );
                    })}
                  <FormRow>
                    <FormColumn>
                      <Input
                        placeholder="Fornavn"
                        error={firstNameError}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </FormColumn>
                    <FormColumn>
                      <Input
                        placeholder="Etternavn"
                        error={lastNameError}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <Input
                        placeholder="E-post"
                        error={emailError}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormColumn>
                    <FormColumn>
                      <Input
                        placeholder="Mobil"
                        error={phoneError}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow className="column mobile-consent">
                    <FormRow className="mobile-consent">
                      <Checkbox
                        checked={generalConsent}
                        label="Jeg aksepterer at mine opplysninger lagres hos meglerforetaket og at jeg vil motta relevant informasjon om denne eiendommen"
                        onClick={() => setGeneralConsent(!generalConsent)}
                      />
                    </FormRow>
                    <FormRow className="mobile-consent">
                      <Checkbox
                        disabled={!generalConsent}
                        checked={keepMeInformed}
                        label="Ønsker å abonnere på nyhetsbrev"
                        onClick={() => setKeepMeInformed(!keepMeInformed)}
                      />
                    </FormRow>
                    <FormRow className="mobile-consent">
                      <Checkbox
                        disabled={!generalConsent}
                        checked={keepMeInformedOnBids}
                        label="Ønsker å bli informert om bud på eiendommen"
                        onClick={() =>
                          setKeepMeInformedOnBids(!keepMeInformedOnBids)
                        }
                      />
                    </FormRow>
                    <FormRow className="mobile-consent">
                      <Checkbox
                        disabled={!generalConsent}
                        checked={wantFinance}
                        label="Ønsker å bli kontaktet vedrørende finansiering"
                        onClick={() => setWantFinance(!wantFinance)}
                      />
                    </FormRow>
                    <FormRow className="mobile-consent">
                      <Checkbox
                        disabled={!generalConsent}
                        checked={wantValuation}
                        label="Ønsker verdivurdering/tilbud på salg av egen bolig"
                        onClick={() => setWantValuation(!wantValuation)}
                      />
                    </FormRow>
                  </FormRow>

                  <FormRow>
                    <Button
                      disabled={!generalConsent ? true : false}
                      onClick={() => null}
                    >
                      {loading ? 'Sender..' : 'Send salgsoppgave'}
                    </Button>
                  </FormRow>
                  <FormRow>
                    <PrivacyLink
                      href="https://www.garanti.no/personvern"
                      onClick={(e) => {
                        e.preventDefault();
                        if (typeof Privacy !== 'undefined') {
                          Privacy.show();
                        }
                      }}
                    >
                      Personvern
                    </PrivacyLink>
                  </FormRow>
                </Form>
              );
            }
          }}
        </Mutation>
      ) : (
        <Thanks>
          <h1>Skjema er ikke tilgjengelig</h1>
        </Thanks>
      )}
    </Scrollable>
  );
};

const Scrollable = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  overflow-y: scroll;
  overflow-x: hidden;

  h1 {
    margin-top: 0;
    color: black;
  }
`;

const PrivacyLink = styled.a`
  margin-top: 20px;
  color: black;
`;

const Thanks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  padding: 50px 0;

  h1 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }
`;

const FormNotification = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background: rgb(200, 200, 200);
  color: white;

  &.error {
    background: rgb(230, 40, 20);
    color: white;
  }
`;
