import React, { Component } from 'react';
import styled from 'styled-components';
import { compose, withApollo, graphql } from 'react-apollo';
import { Spring, animated } from 'react-spring/renderprops.cjs';

import { GET_VIEWINGS } from '../../../client/__graphql__/queries';

const ViewingTab = styled.div`
  position: absolute;
  bottom: 50px;
  margin: auto;
  width: 250px;
  padding: 20px;
  background: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 998;

  &.show-desktop {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  &.show-mobile {
    display: none;
    text-align: center;

    @media screen and (max-width: 960px) {
      position: relative;
      display: block;
      width: 100%;
      bottom: auto;
    }
  }

  span {
    font-size: 16px;
    font-weight: bold;
    color: white;

    @media screen and (max-width: 960px) {
      font-size: 20px;
    }
  }

  .day-name {
    display: inline;
    text-transform: capitalize;
  }
`;

const ViewingDate = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  color: white;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
`;

class Viewing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewings: []
    };
  }

  async componentDidMount() {
    if (this.props.sguid) {
      const {
        data: {
          getObjectData: { viewing }
        }
      } = await this.props.client.query({
        query: GET_VIEWINGS,
        variables: { input: { sguid: this.props.sguid } }
      });
      if (viewing) {
        this.setState({
          viewings: viewing
        });
      }
    }
  }

  render() {
    const { className, galleryShow } = this.props;
    const { viewings } = this.state;
    return (
      <Spring
        immediate={galleryShow}
        from={{ x: 0, t: 'translate3d(-250px, 0, 0)' }}
        to={{
          x: !galleryShow && viewings.length ? 1 : 0,
          t:
            !galleryShow && viewings.length
              ? 'translate3d(0, 0, 0)'
              : 'translate3d(-250px, 0, 0)'
        }}
      >
        {({ x, t }) => {
          if (viewings.length) {
            return (
              <ViewingTab
                className={className}
                style={{ opacity: x, transform: t }}
              >
                <span>Visning</span>
                {viewings.length
                  ? viewings.map((item, index) => (
                      <ViewingDate key={index}>
                        <div className="day-name">{item.dayName}</div>{' '}
                        {item.day}/{item.month} {item.timeFrom} - {item.timeTo}
                      </ViewingDate>
                    ))
                  : null}
              </ViewingTab>
            );
          } else {
            return null;
          }
        }}
      </Spring>
    );
  }
}

export default compose(withApollo)(Viewing);
