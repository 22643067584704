import styled from 'styled-components';

export const GalleryOpen = styled.div`
  transition: opacity 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: solid 2px white;
  font-weight: bold;
  color: white;
  z-index: 1000;
  user-select: none;
  opacity: ${props => (props.show ? 1 : 0)};

  &:hover {
    cursor: pointer;
  }
`;
