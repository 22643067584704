import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @keyframes spin {
    100% {
        transform: rotate(360deg)
    }
  }

  * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    padding: 0;
    margin: 0;
    background: #ffffff;

    @media screen and (max-width: 960px) {
      position: relative;
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  #root {
    width: 100%;

    @media screen and (min-width: 960px) {
      width: 100%;
      height: 100%;
      -webkit-overflow-scrolling: touch;
    }
  }

  body {
    height: auto;

    &.front {
      position: relative;
    }
    
    &.no-scroll {
      position: fixed;
    }
  }
  h1 {
    color: white;
  }
`;
