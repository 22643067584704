import React from 'react';
import styled from 'styled-components';

const StyledInfoLink = styled.div`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  padding: 0 0 10px 0;
  text-align: left;
  margin-bottom: 20px;
  ${props => (props.hidden ? 'visibility: hidden;' : '')}
  border-bottom: solid 2px rgb(220, 220, 220);



  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    font-size: 15px;
  }
`;

export const InfoLink = ({ value, style, inline, hidden, onClick }) => (
  <StyledInfoLink
    style={style}
    inline={inline}
    hidden={hidden}
    onClick={onClick}
  >
    {value}
  </StyledInfoLink>
);
