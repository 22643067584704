import React from 'react';
import styled from 'styled-components';

export const VideoButton = ({ onClick }) => {
  return (
    <StyledVideoButton onClick={onClick}>
      <span>Video</span>
      <StyledVideoButtonIcon>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="play"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6zm-16.2 55.1l-352 208C45.6 483.9 32 476.6 32 464V47.9c0-16.3 16.4-18.4 24.1-13.8l352 208.1c10.5 6.2 10.5 21.4.1 27.6z"
          ></path>
        </svg>
      </StyledVideoButtonIcon>
    </StyledVideoButton>
  );
};

const StyledVideoButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 40px;
  padding: 10px;
  border: solid 2px white;
  border-radius: 5px;
  color: white;
  background: none;
  z-index: 2;
  outline: none;

  @media all and (max-width: 960px) {
    right: 20px;
    left: auto;
    z-index: 1000;
  }

  span {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
  }
`;
const StyledVideoButtonIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;
