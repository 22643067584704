import gql from 'graphql-tag';

export const resolvers = {
  Query: {},
  Mutation: {
    toggleMenu: (_, args, { cache }) => {
      const {
        view: {
          width,
          size: { currentScroll }
        },
        common: {
          menu: { show }
        }
      } = cache.readQuery({
        query: gql`
          query {
            view {
              size {
                width
                currentScroll
              }
            }
            common {
              menu {
                show
              }
            }
          }
        `
      });

      if (width <= 960 && !show) {
        document.body.classList.add('no-scroll');
      } else if (width <= 960 && show) {
        document.body.classList.remove('no-scroll');
        window.scroll(0, currentScroll);
      }

      cache.writeData({
        data: {
          view: {
            __typename: 'View',
            size: {
              __typename: 'Size',
              currentScroll: show ? currentScroll : window.pageYOffset
            }
          },
          common: {
            __typename: 'Common',
            menu: {
              __typename: 'Menu',
              show: args && args.toggle === false ? false : !show,
              showSearch: false
            }
          }
        }
      });

      return null;
    },
    toggleSearch: (_, args, { cache }) => {
      const {
        common: {
          menu: { showSearch }
        }
      } = cache.readQuery({
        query: gql`
          query {
            common {
              menu {
                showSearch
              }
            }
          }
        `
      });

      if (args && args.toggle === false) {
        cache.writeData({
          data: {
            common: {
              __typename: 'Common',
              menu: {
                __typename: 'Menu',
                show: false,
                showSearch: false
              }
            }
          }
        });
      } else {
        cache.writeData({
          data: {
            common: {
              __typename: 'Common',
              menu: {
                __typename: 'Menu',
                show: false,
                showSearch: !showSearch
              }
            }
          }
        });
      }

      return null;
    },
    toggleGallery: (_, args, { cache }) => {
      const { objectPage } = cache.readQuery({
        query: gql`
          query {
            common {
              menu {
                show
              }
            }
            objectPage {
              gallery {
                show
              }
            }
          }
        `
      });
      cache.writeData({
        data: {
          common: {
            __typename: 'Common',
            menu: {
              __typename: 'Menu',
              show: false
            }
          },
          objectPage: {
            __typename: 'ObjectPage',
            gallery: {
              __typename: 'Gallery',
              show: !objectPage.gallery.show
            }
          }
        }
      });
      return null;
    }
  },
  toggleGalleryPreview: (_, args, { cache }) => {
    const { objectPage } = cache.readQuery({
      query: gql`
        query {
          objectPage {
            gallery {
              galleryOpen
            }
          }
        }
      `
    });

    if (objectPage.gallery.galleryOpen) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }

    cache.writeData({
      data: {
        objectPage: {
          __typename: 'ObjectPage',
          gallery: {
            __typename: 'Gallery',
            galleryOpen: !objectPage.gallery.galleryOpen
          }
        }
      }
    });
    return null;
  }
};
