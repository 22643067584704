import styled from 'styled-components';

export const Form = styled.form`
  padding: 20px;
  max-width: 800px;
`;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;

  &.column {
    flex-flow: column;
  }

  @media all and (max-width: 960px) {
    flex-flow: column;
    margin-bottom: 0;

    &.mobile-consent {
      padding: 10px 0;
    }
  }
`;

export const FormColumn = styled.div`
  padding: 0 5px 0 0;
  width: 100%;

  &:nth-child(2) {
    padding-left: 5px;
  }

  @media all and (max-width: 960px) {
    padding: 0;
    margin-bottom: 10px;

    &:nth-child(2) {
      padding-left: 0;
    }
  }
`;
