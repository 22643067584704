import React, { Component } from 'react';
import styled from 'styled-components';
import { compose, withApollo, graphql } from 'react-apollo';
import { TOGGLE_MENU } from '../../../client/__graphql__/mutations';
import { Spring, Trail } from 'react-spring/renderprops.cjs';

import { MenuIcon } from './MenuIcon';

const StyledMobileMenuWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: white;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: scroll;

  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledMobileMenuList = styled.ul`
  list-style: none;
  padding: 100px 40px;
  margin: 0;
  text-align: center;
`;

const StyledMobileMenuLink = styled.li`
  display: block;
  font-size: 23px;
  font-weight: 400;
  color: #444;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        { key: 1, text: 'Selge', href: 'https://garanti.no/selge' },
        { key: 2, text: 'Kjøpe', href: 'https://garanti.no/kjope' },
        { key: 3, text: 'Budgiving', href: 'https://garanti.no/budgivning' },
        { key: 4, text: 'Om oss', href: 'https://garanti.no/omoss' },
        {
          key: 5,
          text: 'Våre kontorer',
          href: 'https://garanti.no/kontorer'
        }
      ]
    };
  }

  render() {
    const { items } = this.state;
    let { show, toggleMenu, kbbl, viewHeight } = this.props;
    return (
      <Spring
        from={{ x: 0, height: 0 }}
        to={{ x: show ? 1 : 0, height: show ? viewHeight : 0 }}
        onRest={() => null}
      >
        {({ x, height }) => (
          <StyledMobileMenuWrapper
            style={{
              height: height
            }}
          >
            <MenuIcon
              style={{ position: 'absolute', top: '40px', right: '25px' }}
              scrolled={false}
              currentTheme="black"
              active={show}
              onClick={() => toggleMenu()}
            />
            <StyledMobileMenuList>
              <Trail
                items={items}
                reverse={x === 0}
                keys={item => item.key}
                from={{
                  opacity: 0
                }}
                to={{
                  opacity: x > 0.6 ? 1 : 0
                }}
              >
                {item => props => (
                  <StyledMobileMenuLink
                    style={props}
                    onClick={() => {
                      toggleMenu();
                      if (typeof window !== 'undefined') {
                        window.location.href = item.href;
                      }
                    }}
                  >
                    {item.text}
                  </StyledMobileMenuLink>
                )}
              </Trail>
            </StyledMobileMenuList>
          </StyledMobileMenuWrapper>
        )}
      </Spring>
    );
  }
}

export default compose(
  withApollo,
  graphql(TOGGLE_MENU, { name: 'toggleMenu' })
)(MobileMenu);
