import React, { Component } from "react";
import styled from "styled-components";
import { Spring } from "react-spring/renderprops.cjs";

import { compose, withApollo, Query, Mutation } from "react-apollo";
import {
  SOLD_CACHE,
  AGENT_CACHE,
  MAP_LOCATION_CACHE,
  BID_LINK_CACHE,
  GET_PRINTABLE,
  NABOLAG_CACHE,
  SMARTBOLIGANALYSE_CACHE,
  VALUATION_LINK_CACHE,
} from "../../../client/__graphql__/queries";

import { POST_INTERESTED_MUTATION } from "../../../client/__graphql__/mutations";

import { CloseIcon } from "./CloseIcon";
import { AgentItem } from "./AgentItem";
import Map from "./Statkart";
import { BestillSalgsoppgave } from "../forms/BestillSalgsoppgave";

const StyledTabWrapper = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => (props.tabOpen ? "white" : "transparent")};
  pointer-events: ${(props) => (props.tabOpen ? "auto" : "none")};
  overflow: hidden;
  z-index: 1;
`;

const StyledTab = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(230, 230, 230);
  pointer-events: inherit;
  overflow-x: hidden;
  overflow-y: hidden;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    overflow-y: scroll;
  }

  &.smartbolig {
    iframe {
      width: 600px;
      height: 330px;
    }
    padding-top: 2rem;
  }

  &.center-content {
    display: flex;
    justify-content: center;
  }

  &.missing-content {
    display: flex;
    justify-content: center;
    padding: 40px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .missing {
    color: rgb(150, 150, 150);
    font-size: 25px;
  }
`;

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
      ready: false,
      iframeLoaded: false,
    };
  }

  _handleClose() {
    const { client } = this.props;
    client.writeData({
      data: {
        objectPage: {
          __typename: "ObjectPage",
          infotab: {
            __typename: "InfoTab",
            sectionOpen: false,
          },
          modal: {
            __typename: "Modal",
            show: false,
          },
        },
      },
    });
    this.setState({
      iframeLoaded: false,
    });
  }

  _handleRemoveContent() {
    const { client } = this.props;
    client.writeData({
      data: {
        objectPage: {
          __typename: "ObjectPage",
          infotab: {
            __typename: "InfoTab",
            currentSection: "",
          },
        },
      },
    });
  }

  _getTab(x, t, ready) {
    let { pane, sguid, height, match } = this.props;
    let path = match && match.params && match.params.p2;
    switch (pane) {
      case "agent":
        return (
          <Query
            query={AGENT_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <StyledTab
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      opacity: x,
                      transform: t,
                      padding: "30px",
                      overflowY: "scroll",
                      height: height,
                      width: "100%",
                    }}
                  >
                    {getObjectData.agents.length
                      ? getObjectData.agents.map((item, index) => {
                          if (!item.hidden) {
                            return (
                              <AgentItem
                                key={index}
                                name={item.name}
                                title={item.title}
                                phone={item.phone}
                                email={item.email}
                                src={item.image}
                                office={item.office}
                                isWhiteLabel={getObjectData.isWhitelabel}
                              />
                            );
                          } else {
                            return <div />;
                          }
                        })
                      : null}
                  </StyledTab>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case "map":
        return (
          <Query
            query={MAP_LOCATION_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid, path } }}
          >
            {({ data: { getObjectData }, loading, error }) => {
              if (getObjectData) {
                return (
                  <StyledTab style={{ opacity: x, transform: t }}>
                    <Map
                      center={{
                        lat: getObjectData.lat,
                        lng: getObjectData.lng,
                      }}
                    />
                  </StyledTab>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case "neighbourhood":
        return (
          <Query
            query={NABOLAG_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid, path } }}
          >
            {({ data: { getObjectData }, loading, error }) => {
              return (
                <StyledTab style={{ opacity: x, transform: t }}>
                  {getObjectData && getObjectData.nabolagprofil ? (
                    <iframe
                      title="nabologsprofil"
                      src={getObjectData && getObjectData.nabolagprofil}
                    />
                  ) : (
                    <FlexWrapper>
                      <h2>Nabolagsprofil ikke funnet</h2>
                    </FlexWrapper>
                  )}
                </StyledTab>
              );
            }}
          </Query>
        );
        break;
      case "smartbolig":
        return (
          <Query
            query={SMARTBOLIGANALYSE_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid, path } }}
          >
            {({ data: { getObjectData }, loading, error }) => {
              return (
                <StyledTab
                  style={{ opacity: x, transform: t }}
                  className="center-content smartbolig"
                >
                  {getObjectData && getObjectData.smartBoligAnalyse ? (
                    <iframe
                      title="smartboliganalyse"
                      src={getObjectData && getObjectData.smartBoligAnalyse}
                    />
                  ) : (
                    <FlexWrapper>
                      <h2>Smart Boliganalyse ikke funnet</h2>
                    </FlexWrapper>
                  )}
                </StyledTab>
              );
            }}
          </Query>
        );
        break;
      case "bid":
        return (
          <Query
            query={BID_LINK_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <StyledTab style={{ opacity: x, transform: t }}>
                    {this.state.ready ? (
                      <iframe
                        onLoad={() => this.setState({ iframeLoaded: true })}
                        title="budgiving"
                        src={getObjectData.bidLink}
                      />
                    ) : null}
                  </StyledTab>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case "valuation":
        return (
          <Query
            query={VALUATION_LINK_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              return (
                <StyledTab style={{ opacity: x, transform: t }}>
                  {ready ? (
                    <iframe
                      onLoad={() => this.setState({ iframeLoaded: true })}
                      title="verdivurdering"
                      src={getObjectData && getObjectData.valuation}
                    />
                  ) : null}
                </StyledTab>
              );
            }}
          </Query>
        );
        break;
      case "printable":
        return (
          <Query
            query={SOLD_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              return (
                <StyledTab
                  className="center-content"
                  style={{ opacity: x, transform: t }}
                >
                  <BestillSalgsoppgave
                    sguid={sguid}
                    sold={getObjectData && getObjectData.sold}
                  />
                </StyledTab>
              );
            }}
          </Query>
        );
        break;
      default:
        return null;
        break;
    }
  }

  render() {
    let { show, showTab } = this.props;
    return (
      <Spring
        from={{ x: 0, t: "translate3d(0, -100px, 0)" }}
        to={{
          x: showTab ? 1 : 0,
          t: showTab ? "translate3d(0, 0, 0)" : "translate3d(0, -30px, 0)",
        }}
        onStart={() => {
          if (this.state.init) {
            this.setState({
              init: false,
            });
          }
          this.setState({
            ready: false,
          });
        }}
        onRest={(props) => {
          setTimeout(() => {
            this.setState({
              ready: props.x === 1 ? true : false,
            });
          }, 100);
        }}
      >
        {({ x, t }) => (
          <StyledTabWrapper show={show} tabOpen={showTab}>
            <CloseIcon
              style={{
                transition: "opacity 0.2s ease-in-out",
                position: "absolute",
                top: "25px",
                right: "20px",
                zIndex: 100,
                opacity: this.state.ready ? 1 : 0,
              }}
              onClick={() => this._handleClose()}
            />
            {this._getTab(x, t, this.state.ready)}
          </StyledTabWrapper>
        )}
      </Spring>
    );
  }
}

const FlexWrapper = styled.div`
  width: 100%;
  padding-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default compose(withApollo)(Tabs);
