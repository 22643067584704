import React from 'react';
import styled from 'styled-components';

export const Checkbox = ({ disabled, label, checked, onClick }) => {
  return (
    <StyledCheckboxWrapper
      onClick={disabled ? () => null : onClick}
      className={disabled ? 'disabled' : ''}
    >
      <StyledCheckbox className={checked ? 'checked' : ''}>
        {checked ? <Checkmark className="checkmark" /> : null}
      </StyledCheckbox>
      {label ? <StyledLabel>{label}</StyledLabel> : null}
    </StyledCheckboxWrapper>
  );
};

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const StyledCheckbox = styled.div`
  flex: 0 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  background: white;

  &.error {
    border: solid 2px rgb(230, 30, 0);
  }

  .checkmark {
    width: 15px;
    height: 15px;

    path {
      fill: black;
    }
  }

  @media all and (max-width: 960px) {
    background: rgb(245, 245, 245);
  }
`;

const Checkmark = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      className={className}
    >
      <g>
        <path
          fill="#FFF"
          d="M849.9,115L360,604.9L150,395l-140,140l210,210l35,35L360,885l630-630.1L849.9,115z"
        />
      </g>
    </svg>
  );
};

const StyledLabel = styled.div`
  font-size: 16px;
  margin-left: 10px;
`;
