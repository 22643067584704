import React from 'react';
import { Helmet } from 'react-helmet-async';
import { formatCurrency } from '../../utils/formatCurrency';

export const Meta = ({
  brandName = 'Garanti Eiendomsmegling',
  title,
  type,
  price,
  description,
  url,
  image
}) => {
  let p =
    price && price.from
      ? formatCurrency(price.from)
      : price && price.value
      ? formatCurrency(price.value)
      : 0;
  return (
    <Helmet>
      {title ? (
        <title>
          {title} -{' '}
          {brandName && brandName.length ? brandName : 'Garanti Eiendomsmegling'}
        </title>
      ) : null}
      {description ? <meta name="description" content={description} /> : null}
      {type && price ? (
        <meta
          property="og:title"
          content={`${
            brandName && brandName.length ? brandName : 'Garanti Eiendomsmegling'
          } - ${type} ${p ? `- ${p}` : ''}`}
        />
      ) : null}
      {title ? <meta property="og:description" content={title} /> : null}
      <meta property="og:type" content="website" />
      {image ? <meta property="og:image" content={image ? image : ''} /> : null}
      {image ? <meta itemProp="image" content={image ? image : ''} /> : null}
      {url && url.title ? (
        <meta
          property="og:url"
          content={`https://garanti.no/${url.title}/${url.sguid}`}
        />
      ) : null}
    </Helmet>
  );
};
