import styled from "styled-components";

export const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(${(props) =>
      props.white
        ? "https://prod-objektside-garanti.reeltime.no/objektside/images/close_icon.svg"
        : "https://prod-objektside-garanti.reeltime.no/objektside/images/close_icon_black.svg"})
    no-repeat;
  background-size: contain;
  background-position: center;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;
