import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledFooter = styled.div`
  padding: 40px;
  width: 100%;
  background: black;
  float: left;
`;

const StyledFooterHeading = styled.h3`
  font-size: 14px;
  color: white;
`;

const StyledFooterRow = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 200px;
  margin-bottom: 30px;
`;

const StyledFooterColumn = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 20px;
  flex: 0 1 200px;
`;

const StyledFooterText = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  color: white;
`;

const StyledFooterAnchor = styled.a`
  margin-top: 8px;
  font-size: 14px;
  color: white;
  text-decoration: none;
`;

const StyledFooterLink = styled(Link)`
  font-size: 14px;
  color: white;
  text-decoration: none;
`;

const StyledLogo = styled.div`
  display: block;
  margin-top: 20px;
  width: 100px;
  height: 50px;
  background: url('/images/logo.svg') no-repeat;
  background-size: contain;
  background-position: left center;
`;

export const Footer = () => (
  <StyledFooter>
    <StyledFooterHeading>Administrasjon</StyledFooterHeading>
    <StyledFooterRow>
      <StyledFooterColumn>
        <StyledFooterText>22 91 00 80 (telefon)</StyledFooterText>
        <StyledFooterText>22 91 00 89 (faks)</StyledFooterText>
        <StyledFooterAnchor href="mailto:post@garanti.no">
          post@garanti.no
        </StyledFooterAnchor>
      </StyledFooterColumn>
    </StyledFooterRow>
    <StyledFooterRow>
      <StyledFooterColumn>
        <StyledFooterHeading>Daglig leder</StyledFooterHeading>
        <StyledFooterText>Per Hultgren</StyledFooterText>
        <StyledFooterText>91 18 66 29</StyledFooterText>
        <StyledFooterAnchor href="mailto:ph@garanti.no">
          ph@garanti.no
        </StyledFooterAnchor>
      </StyledFooterColumn>
      <StyledFooterColumn>
        <StyledFooterHeading>Markedsfører</StyledFooterHeading>
        <StyledFooterText>Mailén Eriksen</StyledFooterText>
        <StyledFooterText>92 23 48 97</StyledFooterText>
        <StyledFooterAnchor href="mailto:mailen.eriksen@garanti.no">
          mailen.eriksen@garanti.no
        </StyledFooterAnchor>
      </StyledFooterColumn>
    </StyledFooterRow>
    <StyledFooterRow>
      <StyledFooterColumn>
        <StyledFooterHeading>Kundekontakt</StyledFooterHeading>
        <StyledFooterText>Kari Vangen</StyledFooterText>
        <StyledFooterText>22 91 00 82</StyledFooterText>
        <StyledFooterAnchor href="mailto:kari.vangen@garanti.no">
          kari.vangen@garanti.no
        </StyledFooterAnchor>
      </StyledFooterColumn>
      <StyledFooterColumn>
        <StyledFooterHeading>IT-Ansvarlig</StyledFooterHeading>
        <StyledFooterText>Per Werner Schulze</StyledFooterText>
        <StyledFooterText>90 04 63 90</StyledFooterText>
        <StyledFooterAnchor href="mailto:support@garanti.no">
          support@garanti.no
        </StyledFooterAnchor>
      </StyledFooterColumn>
    </StyledFooterRow>
    <StyledFooterRow>
      <StyledFooterColumn>
        <StyledFooterLink to="/">
          <StyledLogo />
        </StyledFooterLink>
      </StyledFooterColumn>
    </StyledFooterRow>
  </StyledFooter>
);
