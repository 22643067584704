import styled from "styled-components";

export const Loader = styled.div`
  animation: spin 1.2s linear infinite;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: ${(props) => (props.small ? props.small : "30px")};
  height: ${(props) => (props.small ? props.small : "30px")};
  background: url("https://prod-objektside-garanti.reeltime.no/objektside/images/loader.svg")
    no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: none;
`;
