import React, { Component } from "react";
import styled from "styled-components";

const StyledMoreDetailsBtn = styled.button`
  position: relative;
  padding: 10px 25px 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: black;
  background: none;
  border: none;
  outline: none;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledMoreDetailsAddIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  background: url("https://prod-objektside-garanti.reeltime.no/objektside/images/add.svg")
    no-repeat;
  background-position: center;
  background-size: contain;
`;

class MoreDetailsBtn extends Component {
  render() {
    const { children, onClick } = this.props;
    return (
      <StyledMoreDetailsBtn onClick={onClick}>
        {children ? children : null}
        <StyledMoreDetailsAddIcon />
      </StyledMoreDetailsBtn>
    );
  }
}

export default MoreDetailsBtn;
