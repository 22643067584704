import React from 'react';
import moment from 'moment';
import { getSizeData } from '../utils/getSizeData';

export const SizeInfoField = ({ data }) => {
  if (!data) return null;
  const mainSize = getSizeData(data);
  const mainTitle = mainSize && mainSize.type === 'usableInternal' ? 'BRA-I' : 'P-rom';
  const { bta, bra, size } = data;
  const hasData =
    (mainSize && mainSize.from) ||
    bra ||
    bta ||
    (size && (size.usableExternalArea || size.enclosedBalconyArea || size.openArea))
      ? true
      : false;
  if (!hasData) return null;
  return (
    <>
      <h2>Størrelse</h2>
      {mainSize && mainSize.from && mainSize.to ? (
        <>
          <span>
            {mainTitle}: {mainSize.from} m<sup>2</sup> - {mainSize.to}m<sup>2</sup>
          </span>
          <br />
        </>
      ) : null}
      {mainSize && mainSize.from && !mainSize.to ? (
        <>
          <span>
            {mainTitle}: {mainSize.from} m<sup>2</sup>
          </span>
          <br />
        </>
      ) : null}
      {size && size.usableExternalArea ? (
        <>
          <span>
            BRA-E: {size.usableExternalArea} m<sup>2</sup>
          </span>
          <br />
        </>
      ) : null}
      {size && size.enclosedBalconyArea ? (
        <>
          <span>
            BRA-B: {size.enclosedBalconyArea} m<sup>2</sup>
          </span>
          <br />
        </>
      ) : null}
      {bra ? (
        <>
          <span>
            BRA: {bra} m<sup>2</sup>
          </span>
          <br />
        </>
      ) : null}
      {bta ? (
        <>
          <span>
            BTA: {bta} m<sup>2</sup>
          </span>
          <br />
        </>
      ) : null}
      {size && size.openArea ? (
        <>
          <span>
            TBA: {size.openArea} m<sup>2</sup>
          </span>
          <br />
        </>
      ) : null}
    </>
  );
};
