import React, { Component } from "react";
import styled from "styled-components";
import { Loader } from "./Loader";

const StyledAgentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  float: left;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledAgentImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: rgb(220, 220, 220);
`;

const StyledAgentImage = styled.div`
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: ${(props) => (props.loaded ? `url(${props.src}) no-repeat` : "")};
  background-position: center;
  background-size: cover;
  opacity: ${(props) => (props.loaded ? 1 : 0)};

  .preload {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  @media screen and (max-width: 960px) {
    flex: 0 0 150px;
    width: 150px;
    height: 150px;
  }
`;

const StyledAgentInfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 20px;
  text-align: left;

  &:hover {
    cursor: pointer;
  }

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    color: black;
  }
`;

const StyledAgentContactItem = styled.a`
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  color: black;
  float: left;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    position: relative;
    top: -2px;
    content: "";
    width: 15px;
    height: 15px;
    background: ${(props) =>
        props.mail
          ? "url(https://prod-objektside-garanti.reeltime.no/objektside/images/envelope_black.png)"
          : "url(https://prod-objektside-garanti.reeltime.no/objektside/images/phone_black.png)"}
      no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
    float: left;
  }
`;

class AgentItemImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      fallback: false,
    };
  }

  _loaded() {
    this.setState({
      loaded: true,
    });
  }

  _fallback() {
    this.setState({
      fallback: true,
    });
  }

  render() {
    const { src } = this.props;
    return (
      <StyledAgentImageWrapper>
        <StyledAgentImage src={src} loaded={this.state.loaded}>
          <img
            alt=""
            className="preload"
            src={src}
            onLoad={() => this._loaded()}
            onError={() => this._fallback()}
          />
        </StyledAgentImage>
        <Loader show={!this.state.loaded && !this.state.fallback} />
      </StyledAgentImageWrapper>
    );
  }
}

const StyledAgentOfficeLink = styled.div`
  font-size: 12px;
  color: #333;
  margin: 5px 0;

  a {
    font-weight: bold;
    color: black;
    text-decoration: none;
  }
`;

export const AgentItemTab = ({
  name,
  title,
  phone,
  email,
  src,
  office,
  isWhiteLabel,
}) => (
  <StyledAgentWrapper>
    <AgentItemImage src={src} />
    <StyledAgentInfoWrapper>
      <h2
        onClick={() => {
          if (typeof window !== "undefined" && !isWhiteLabel) {
            window.open(`https://garanti.no/${name.replace(" ", "_")}`);
          }
        }}
      >
        {name}
      </h2>
      <h3>{title}</h3>
      {office && office.href ? (
        <StyledAgentOfficeLink>
          Jobber for{" "}
          <a
            href={
              isWhiteLabel ? office.href : `https://garanti.no/${office.href}`
            }
            target="_blank"
          >
            {office.name}
          </a>
        </StyledAgentOfficeLink>
      ) : null}
      <StyledAgentContactItem href={`tel:${phone}`} phone="true">
        {phone}
      </StyledAgentContactItem>
      <StyledAgentContactItem href={`mailto:${email}`} mail="true">
        {email}
      </StyledAgentContactItem>
    </StyledAgentInfoWrapper>
  </StyledAgentWrapper>
);
