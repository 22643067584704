import React, { Component } from 'react';
import styled from 'styled-components';

const MapDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
`;

class Maps extends Component {
  state = { width: 0, height: 0 };
  async componentDidMount() {
    setTimeout(() => {
      const { center } = this.props;
      const ref = document.getElementById('statkart');
      if (
        L &&
        ref &&
        center &&
        center.lat !== undefined &&
        center.lat !== null &&
        center.lng !== undefined &&
        center.lng !== null
      ) {
        const map = L.map(ref, {
          keyboard: false,
          dragging: true,
          zoomControl: true,
          boxZoom: false,
          doubleClickZoom: false,
          scrollWheelZoom: true,
          tap: false,
          touchZoom: true,
          center: [51.505, -0.09],
          zoom: 18,
          minZoom: 10,
          maxZoom: 18
        }).setView([center.lat, center.lng], 18);
        L.tileLayer(
          'https://cache.kartverket.no/v1/wmts/1.0.0/topograatone/default/webmercator/{z}/{y}/{x}.png',
          {
            attribution: '<a href="http://www.kartverket.no/">Kartverket</a>'
          }
        ).addTo(map);
        L.marker([center.lat, center.lng]).addTo(map);
      }
    }, 1400);
  }
  render() {
    let { google, center } = this.props;
    return <MapDiv id="statkart" />;
  }
}

export default Maps;
