import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { Query, compose, withApollo, graphql } from "react-apollo";
import {
  GET_SINGLE_OBJECT_IMAGES,
  CACHE,
} from "../../../client/__graphql__/queries";

import { GalleryOpen } from "./OpenGallery";
import { VideoButton } from "./VideoButton";
import { Loader } from "./Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

let Flickity;

if (typeof window !== "undefined") {
  Flickity = require("flickity");
}

const SlideshowLoader = styled(Loader)`
  @media screen and (max-width: 960px) {
    top: 40px;
  }
`;

const StyledSlideshowGradientTop = styled.div`
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 1;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const StyledSlideshowGradient = styled.div`
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  z-index: 1;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
`;

const StyledSlideshowOverlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;

  @media screen and (max-width: 960px) {
    display: ${(props) => (props.show ? "none" : "block")};
  }
`;

const StyledSlideshowWrapper = styled.div`
  transition: background 0s linear 0.3s;
  position: relative;
  width: 100%;
  height: 100vh;
  background: ${(props) => (props.isloading ? "#ffffff" : "#000000")};
  overflow: hidden;

  @media screen and (max-width: 960px) {
    position: ${(props) => (props.show ? "fixed" : "relative")};
    height: ${(props) => (props.show ? "100vh" : "50vh")};
    ${(props) => (props.show ? "z-index: 999;" : "")}

    .flickity-viewport {
      ${(props) => (props.show ? "height: 100vh !important" : "")}
    }
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
`;

const StyledSlide = styled.div`
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transform: ${(props) =>
    !props.gallery ? "scale3d(1, 1, 1)" : "scale3d(0.95, 0.95, 0.95)"};

  @media only screen and (max-device-width: 1024px) {
    transform: none !important;
  }
`;

const Img = styled.img`
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: ${(props) => (props.gallery ? "100%" : "auto")};
  object-fit: ${(props) => (!props.gallery ? "cover" : "contain")};
  font-family: "object-fit: cover;";
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const StyledSlideInfoWrapper = styled.div`
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0 20px;
  user-select: none;
  pointer-events: none;
  z-index: 99;
  transform: ${(props) =>
    props.show ? "translate3d(0, 0, 0)" : "translate3d(0, 20px, 0)"};
  opacity: ${(props) => (props.show ? 1 : 0)};

  @media only screen and (max-device-width: 1024px) {
    transform: translate3d(0, -50px, 0);
  }
`;

const StyledSlideInfo = styled.div`
  background: rgba(238, 238, 238, 0.8);
  padding: 20px;
`;

const StyledSlideCounter = styled.div`
  display: inline;
  font-weight: bold;
  float: left;
  margin-right: 20px;
`;

const StyledSlideDescription = styled.div`
  display: inline;
  float: left;
`;

const StyledController = styled.div`
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  background: url("https://prod-objektside-garanti.reeltime.no/objektside/images/${(
      props
    ) => props.icon}")
    no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1000;
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  ${(props) => (props.left ? "left: 40px" : "right: 40px")};

  &:hover {
    cursor: pointer;
  }
`;

const GalleryOverlay = styled.div`
  transition: opacity 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  padding: 80px 80px 20px 80px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 100;
  opacity: ${(props) => (props.open ? 1 : 0)};
  pointer-events: ${(props) => (props.open ? "auto" : "none")};
  -webkit-overflow-scrolling: touch;

  img {
    height: 150px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: solid 3px transparent;

    &.active {
      border: solid 3px #fff;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      index: 0,
      images: [],
      width: 0,
      height: 0,
      loading: true,
      galleryOpen: false,
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { data } = await client.query({
      query: GET_SINGLE_OBJECT_IMAGES,
      variables: {
        input: {
          sguid: this.props.sguid,
        },
      },
    });
    if (data && data.objectImages && data.objectImages.images) {
      this.setState(
        {
          images: data.objectImages.images,
        },
        () => {
          this.initializeFlickity();
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps && prevProps.show !== this.props.show) {
      if (this.props.show === true && this._slideshowRef) {
        this._slideshowRef.focus();
      } else if (this.props.show === false) {
        this.initializeFlickity();
        this.flickity.select(0, false, true);
      }
    }
  }

  initializeFlickity() {
    this.flickity = new Flickity(this._slideshowRef, {
      pageDots: false,
      adaptiveHeight: false,
      prevNextButtons: false,
      setGallerySize: false,
      lazyLoad: 2,
      on: {
        ready: () => {
          this.setState({
            loaded: true,
          });
        },
        change: (index) => {
          this.setState({ index });
        },
      },
    });
  }

  _loaded() {
    const { client } = this.props;
    this.setState({
      loading: false,
    });

    client.writeData({
      data: {
        objectPage: {
          __typename: "ObjectPage",
          gallery: {
            __typename: "Gallery",
            loading: false,
          },
        },
      },
    });
  }

  _close() {
    const { client } = this.props;
    client.writeData({
      data: {
        objectPage: {
          __typename: "ObjectPage",
          gallery: {
            __typename: "Gallery",
            show: false,
            galleryOpen: false,
            showVideo: false,
          },
        },
      },
    });
  }

  _selectGalleryImage(index) {
    const { client } = this.props;
    this.flickity.select(index, false, true);
    client.writeData({
      data: {
        objectPage: {
          __typename: "ObjectPage",
          gallery: {
            __typename: "Gallery",
            galleryOpen: false,
          },
        },
      },
    });
  }

  isVimeo(embedCode) {
    if (!embedCode) return "";
    if (typeof embedCode !== "string") return "";
    return embedCode.match(/^\d+$/)
      ? `https://player.vimeo.com/video/${embedCode}`
      : `https://www.youtube.com/embed/${embedCode}`;
  }

  render() {
    let { show, onGalleryOpen, images, videoLink, videoButton, client } =
      this.props;
    return (
      <Query query={CACHE}>
        {({ data: { objectPage } }) => {
          return (
            <StyledSlideshowWrapper show={show} isloading={this.state.loading}>
              {videoButton}
              {!objectPage.gallery.showVideo ? (
                <GalleryOverlay open={objectPage.gallery.galleryOpen}>
                  {this.state.images && objectPage.gallery.galleryOpen
                    ? this.state.images.map((item, index) => {
                        return (
                          <LazyLoadImage
                            key={`preview-image-${index}`}
                            alt=""
                            effect="blur"
                            visibleByDefault={false}
                            className={
                              this.state.index === index ? "active" : ""
                            }
                            src={item.w2000}
                            onClick={() => this._selectGalleryImage(index)}
                          />
                        );
                      })
                    : null}
                </GalleryOverlay>
              ) : null}
              <SlideshowLoader show={this.state.loading} />
              <StyledSlideshowOverlay show={show}>
                <GalleryOpen show={!this.state.loading} onClick={onGalleryOpen}>
                  Galleri
                </GalleryOpen>

                {videoLink ? (
                  <VideoButton
                    onClick={() => {
                      client.writeData({
                        data: {
                          objectPage: {
                            __typename: "ObjectPage",
                            gallery: {
                              __typename: "Gallery",
                              galleryOpen: true,
                              show: true,
                              showVideo: true,
                            },
                          },
                        },
                      });
                    }}
                  />
                ) : null}
              </StyledSlideshowOverlay>
              <StyledSlideshowGradient show={!show} />
              <StyledSlideshowGradientTop show={!show} />
              <Fragment>
                {!objectPage.gallery.showVideo ? (
                  <StyledSlideInfoWrapper show={show}>
                    <StyledSlideInfo>
                      <StyledSlideCounter>
                        {this.state.index + 1} / {this.state.images.length}
                      </StyledSlideCounter>
                      {this.state.images &&
                      this.state.images[this.state.index] &&
                      this.state.images[this.state.index].description ? (
                        <StyledSlideDescription>
                          {this.state.images[this.state.index].description}
                        </StyledSlideDescription>
                      ) : null}
                    </StyledSlideInfo>
                  </StyledSlideInfoWrapper>
                ) : null}
                {!objectPage.gallery.showVideo ? (
                  <StyledController
                    left
                    icon="arrow_left.svg"
                    show={
                      show &&
                      this.state.index > 0 &&
                      !objectPage.gallery.galleryOpen
                    }
                    onClick={() => this.flickity.previous()}
                  />
                ) : null}
                {!objectPage.gallery.showVideo ? (
                  <StyledController
                    right
                    icon="arrow_right.svg"
                    show={
                      show &&
                      this.state.index + 1 < this.state.images.length &&
                      !objectPage.gallery.galleryOpen
                    }
                    onClick={() => this.flickity.next()}
                  />
                ) : null}{" "}
                {objectPage.gallery.showVideo ? (
                  <GalleryVideo>
                    <iframe src={this.isVimeo(videoLink)}></iframe>
                  </GalleryVideo>
                ) : null}
                {!objectPage.gallery.showVideo ? (
                  <div
                    ref={(node) => (this._slideshowRef = node)}
                    className="carousel"
                  >
                    {this.state.images.length
                      ? this.state.images.map((item, index) => {
                          if (index === 0) {
                            return (
                              <StyledSlide gallery={show} key={index}>
                                <Img
                                  alt=""
                                  data-flickity-lazyload={item.w2000}
                                  gallery={show}
                                  show={!this.state.loading}
                                  onLoad={() => this._loaded()}
                                />
                              </StyledSlide>
                            );
                          } else {
                            return (
                              <StyledSlide gallery={show} key={index}>
                                <Img
                                  alt=""
                                  data-flickity-lazyload={item.w2000}
                                  gallery={show}
                                  show={true}
                                  src={item.w2000}
                                />
                              </StyledSlide>
                            );
                          }
                        })
                      : null}
                  </div>
                ) : null}
              </Fragment>
            </StyledSlideshowWrapper>
          );
        }}
      </Query>
    );
  }
}

const GalleryVideo = styled.div`
  width: 100%;
  height: 100vh;
  float: left;
  iframe {
    width: 100%;
    height: 100vh;
  }
`;

export default compose(withApollo)(Slideshow);
